import { SubscriptionsClient } from '@monetization/hpaip-notification-rules-react'
import { getEnvironmentVariables, getStack } from 'src/helpers'

type PatchSubscriptionFn = (
  subscriptionId?: string,
  data?: Record<string, any>
) => Promise<void>

export type SubscriptionResultTuple = {
  patch: PatchSubscriptionFn
}

export const useSubscription = ({
  authProvider,
  stack
}): SubscriptionResultTuple => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const BL_ID = envVariables?.variables?.BL_ID
  /**
   * Method to update subscription state and entities
   */
  const patch = async (
    subscriptionId: string,
    data: Record<string, any>
  ): Promise<any> => {
    const webClient = new SubscriptionsClient(authProvider, stack)

    const res = await webClient.update(subscriptionId, BL_ID, data)
    return res?.data
  }

  return { patch }
}
