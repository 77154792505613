import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { size2, lineHeight3 } = tokens.typography
const { size5 } = tokens.layout

export const Text = styled.p`
  font-size: ${size2};
  line-height: ${lineHeight3};
  margin-bottom: ${size5};
`
