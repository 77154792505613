import {
  AnalyticsProvider,
  parseAuxParams,
  ShellAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React, { useMemo } from 'react'
import { analyticsEvents } from '../analyticsEvents'

interface Props {
  analytics: ShellAnalytics
}

export const UpdatePlanAnalyticsProvider: React.FC<Props> = ({
  children,
  analytics
}) => {
  const auxParams = useMemo(() => {
    const params = parseAuxParams({
      subscriptionId: '955995136000',
      subscriptionStartDate: '2024-04-22T17:15:33.001Z'
    })

    return params
  }, [])

  return useMemo(
    () => (
      <AnalyticsProvider
        analytics={analytics}
        defaults={{
          screenPath: 'ReactPaasDashboardUpdatePlan',
          activity: 'HpOnePrintMgmt-v01',
          version: '1.3.0',
          ...auxParams
        }}
        events={analyticsEvents}
      >
        {children}
      </AnalyticsProvider>
    ),
    [analytics, auxParams, children]
  )
}
