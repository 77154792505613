import React from 'react'
import {
  NotificationPage,
  NotificationContent,
  NotificationTitle,
  NotificationDescription,
  NotificationRevertTitle,
  NotificationRevertDescription
} from './PendingNotification.styles'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import Button from '@veneer/core/dist/scripts/button'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
const PendingNotification = ({
  t,
  pendingNotificationType,
  pendingOffer,
  nextBillingCycleStartDate,
  showRevertloader,
  handleRevertPlan
}) => {
  return (
    <>
      {pendingNotificationType === 'planPending' && (
        <NotificationPage $type={pendingNotificationType}>
          <IconInfo filled size={24} color={'gray6'}></IconInfo>
          <NotificationContent>
            <NotificationTitle>
              {t(
                'update-plan.notification.pending-plan.title',
                'Plan change pending'
              )}
            </NotificationTitle>
            <NotificationDescription>
              {t(
                'update-plan.notification.pending-plan.description1',
                `Your plan will change to the`
              )}
              {` ` + pendingOffer?.pages + ` `}
              {t(
                'update-plan.notification.pending-plan.description2',
                `pages/month plan on`
              )}
              {` ` + nextBillingCycleStartDate}.<br></br>
              {t(
                'update-plan.notification.pending-plan.description3',
                'All costs listed for plan changes are based on the current monthly plan.'
              )}
            </NotificationDescription>
            {showRevertloader ? (
              <ProgressIndicator
                data-testid="loading-indicator"
                appearance="circular"
                behavior="indeterminate"
                color="hpBlue6"
                style={{ marginTop: '8px' }}
              />
            ) : (
              <Button
                data-testid="cancelplanclick"
                appearance="ghost"
                onClick={() => handleRevertPlan()}
                style={{
                  padding: '0px',
                  color: 'hpBlue7',
                  fontSize: '12px',
                  lineHeight: '16px',
                  background: 'none'
                }}
              >
                {t(
                  'update-plan.notification.pending-plan.link',
                  'Cancel plan change'
                )}
              </Button>
            )}
          </NotificationContent>
        </NotificationPage>
      )}
      {pendingNotificationType === 'planRevertError' && (
        <NotificationPage $type={pendingNotificationType}>
          <IconWarningAlt filled size={24} color={'darkOrange5'} />
          <NotificationContent>
            <NotificationRevertTitle>
              {t(
                'update-plan.notification.pending-plan-revert.title',
                'We were unable to revert your plan. Please try again.'
              )}
            </NotificationRevertTitle>
            {showRevertloader ? (
              <ProgressIndicator
                data-testid="loading-indicator"
                appearance="circular"
                behavior="indeterminate"
                color="hpBlue6"
                style={{ marginTop: '4px', marginBottom: '8px' }}
              />
            ) : (
              <Button
                data-testid="cancelplanclick"
                appearance="ghost"
                onClick={() => handleRevertPlan()}
                style={{
                  padding: '0px',
                  color: 'hpBlue7',
                  fontSize: '12px',
                  lineHeight: '16px',
                  background: 'none',
                  marginBottom: '12px'
                }}
              >
                {t(
                  'update-plan.notification.pending-plan.link',
                  'Cancel plan change'
                )}
              </Button>
            )}
            <NotificationRevertDescription>
              {t(
                'update-plan.notification.pending-plan-revert.description1',
                'For assistance, visit HP'
              )}{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.hp.com/all-in-plan/printer-support"
              >
                {t(
                  'update-plan.notification.pending-plan-revert.description2',
                  '24/7 Pro live support.'
                )}
              </a>
            </NotificationRevertDescription>
          </NotificationContent>
        </NotificationPage>
      )}
    </>
  )
}

export { PendingNotification }
