import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@veneer/theme'
import React from 'react'
import { createApolloClient } from '../api/adapters'
import { TranslatorFunctionType } from '../types/localization'
import { MfePropsType } from '../types/mfeProps'
import SubscriptionInfo from 'src/components/SubscriptionInfo/SubscriptionInfo'

export const Root = ({
  t,
  authProvider,
  stack,
  analytics,
  localization,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const graphqlClient = createApolloClient(authProvider, stack)

  return (
    <ThemeProvider density="high">
      <ApolloProvider client={graphqlClient}>
        <SubscriptionInfo
          commonProps={{
            authProvider,
            stack,
            t,
            analytics,
            localization,
            navigation
          }}
        />
      </ApolloProvider>
    </ThemeProvider>
  )
}
