import tokens from '@veneer/tokens'
import styled from 'styled-components'
const { size3: layoutSize3, size1: layoutSize1 } = tokens.layout
const {
  size0,

  family0,

  size1,
  lineHeight2,
  lineHeight1
} = tokens.typography
const { gray12, gray9, hpBlue7 } = tokens.color

export const NotificationPage = styled.div<{
  $type?: string
}>`
  width: 100%;
  height: auto;
  padding: 12px 40px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  align-self: stretch;
  background: ${({ $type }) =>
    $type == 'planRevertError'
      ? 'rgba(255, 137, 0, 0.10)'
      : 'rgba(102, 102, 102, 0.1)'};
  gap: ${layoutSize3};
`
export const NotificationContent = styled.div`
  font-family: ${family0};
`
export const NotificationTitle = styled.p`
  color: ${gray12};
  font-size: ${size1};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight2};
  margin-bottom: ${layoutSize1};
`
export const NotificationDescription = styled.p`
  color: ${gray9};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  margin-bottom: ${layoutSize3};
`
export const NotificationButton = styled.a`
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${hpBlue7};
  cursor: pointer;
`
export const NotificationRevertTitle = styled.p`
  color: ${gray12};
  font-size: ${size1};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight2};
`
export const NotificationRevertDescription = styled.p`
  color: ${gray9};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};

  > a {
    color: ${hpBlue7};
  }
`
