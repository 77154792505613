import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace
    .getTracer('pass-dashboard-update')
    .startSpan('pass-dashboard-update', {
      attributes: {
        'workflow.name': 'jarvis/pass-dashboard-update.error',
        error: true,
        'error.message': message
      }
    })
  span.end()
}

export default sendOpenTelemetryEvent
