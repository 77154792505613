import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query ProductBundle($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        __typename
        name
        sku
        ... on BundleProduct {
          hp_max_days_to_return
          items {
            sku
            title
            options {
              label
              product {
                hp_short_config_name
                image {
                  url
                }
                thumbnail {
                  url
                }
                sku
                __typename
                ... on SimpleProduct {
                  hp_as_low_as_price
                }
                ... on VirtualProduct {
                  hp_pages_per_month
                  hp_product_type_label
                  hp_rollover_pages
                  hp_overage_block_size
                  hp_overage_block_price
                  hp_max_rollover_pages
                  hp_trial_pages_per_month
                  price {
                    regularPrice {
                      amount {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      total_count
      page_info {
        page_size
      }
    }
  }
`
