import {
  BillingCyclePeriodsClient,
  BillingCyclePeriodsResponse
} from '@monetization/hpaip-notification-rules-react'
import { useState } from 'react'
import { getEnvironmentVariables, getStack } from 'src/helpers'
import sendOpenTelemetryEvent from 'src/utils/sendOpenTelemetryEvent'

type QueryResult<T> = {
  data: T
  loading: boolean
  error: Error
}

type QueryExecFunction = (
  subscriptionId: string,
  entityId: string
) => Promise<void>

export type BillingCyclesResultTuple = [
  QueryExecFunction,
  QueryResult<BillingCyclePeriodsResponse[]>
]

export const useBillingCycle = ({
  authProvider,
  stack
}): BillingCyclesResultTuple => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>(null)
  const [data, setData] = useState<BillingCyclePeriodsResponse[]>(null)
  const envVariables = getEnvironmentVariables(getStack(stack))
  const BL_ID = envVariables?.variables?.BL_ID

  const getBillingCycles = async (
    subscriptionId: string,
    entityId: string
  ): Promise<void> => {
    setLoading(true)
    try {
      const webClient = new BillingCyclePeriodsClient(authProvider, stack)

      const response = (await webClient.getBillingCyclesByEntityId(
        BL_ID,
        subscriptionId,
        entityId
      )) as BillingCyclePeriodsResponse[]

      setData(response)
    } catch (err) {
      if (err?.response?.status === 404) return setData(null)
      else {
        sendOpenTelemetryEvent(JSON.stringify(err))
      }
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return [getBillingCycles, { data, loading, error }]
}
