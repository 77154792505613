import React from 'react'
import { StyledText } from '../Page/Page.styles'
import { Container, StyledAnchor } from './styles'
import { navigationLinks } from '@monetization/hpaip-notification-rules-react'

interface Props {
  t: any
}

const DisclaimerText: React.FunctionComponent<Props> = ({ t }) => {
  const { hpOneTerms } = navigationLinks

  return (
    <Container>
      <StyledText>
        {t(
          'update-plan.disclaimer-text.when-you-update',
          'When you update your plan, the new plan (including rollover cap) will take effect at the beginning of your next billing period. You can change plans as often as you like; the most recent change prior to the end of your billing cycle is the version that will go into effect. For complete service details, see'
        )}{' '}
        <StyledAnchor
          href={hpOneTerms}
          target="_blank"
          rel="noreferrer"
          lineHeight={4}
          id="terms-of-service-link"
        >
          {t(
            'update-plan.disclaimer-text.hp-one-terms',
            'HP All-In Plan terms of service.'
          )}
        </StyledAnchor>
      </StyledText>
    </Container>
  )
}

export { DisclaimerText }
