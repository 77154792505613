import {
  AddressClient,
  Address,
  useLazyFetchRequest
} from '@monetization/hpaip-notification-rules-react'
import { useEffect, useMemo } from 'react'
import { CommonProps } from 'src/types/mfeProps'

const useShippingAddress = (
  shippingAddressId: string,
  commonProps: CommonProps
) => {
  const addressClient = useMemo(
    () => new AddressClient(commonProps?.authProvider, commonProps?.stack),
    [commonProps?.authProvider, commonProps?.stack]
  )
  const [fetchAddress, { called, data }] = useLazyFetchRequest<Address>(() => {
    return addressClient.getAddressById(shippingAddressId)
  })

  useEffect(() => {
    if (shippingAddressId && !called) {
      fetchAddress()
    }
  }, [called, fetchAddress])

  return {
    shippingAddress: data
  }
}

export default useShippingAddress
