import {
  AnalyticsEvents,
  CdmEvent
} from '@monetization/hpaip-ui-shared-components'

type CustomAnalyticsEvent = Partial<CdmEvent>

interface PagesPerMonth extends CustomAnalyticsEvent {
  actionAuxParams: string
  controlDetail: string
}

type UpdateTypes =
  | 'UpgradeSuccessNotification'
  | 'DowngradeSuccessNotification'
  | 'UpgradeErrorNotification'
  | 'DowngradeErrorNotification'

interface UpdateNotificationEvent extends CustomAnalyticsEvent {
  screenName: UpdateTypes
}

interface ClickNotificationSupportLinkEvent extends CustomAnalyticsEvent {
  screenName: 'SubscriptionHasBeenCancelled' | UpdateTypes
  screenMode?:
    | 'PleaseReturn'
    | 'ReturnProcessing'
    | 'ReturnSuccess'
    | 'ReturnFailure'
    | 'PostRemorse'
}

// @todo:
// confirmar: AddPaper
export const analyticsEvents: AnalyticsEvents = {
  DisplayedUpdatePlan: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ScreenDisplayed'
  }),
  DisplayedCurrentPlan: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ModuleDisplayed',
    controlName: 'CurrentPlan',
    controlDetail
  }),
  DisplayedRecommended: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ModuleDisplayed',
    controlName: 'Recommended',
    controlDetail
  }),
  DisplayedAddPaper: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ModuleDisplayed',
    controlName: 'AddPaper'
  }),
  ClickSelectPlan: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ControlTileClicked',
    controlName: 'SelectPlan',
    controlDetail
  }),
  ClickPrintPaymentHistory: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ControlHyperLinkClicked',
    controlName: 'PrintPaymentHistory'
  }),
  ClickTermsOfService: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/',
    screenName: 'UpdatePlan',
    action: 'ControlHyperLinkClicked',
    controlName: 'TermsOfService'
  }),
  // ------------- Notifications --------------
  UpdateNotification: ({ screenName }: UpdateNotificationEvent) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName,
    action: 'ModuleDisplayed'
  }),
  DismissUpdateNotification: ({ screenName }: UpdateNotificationEvent) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName,
    action: 'ControlButtonClicked',
    controlName: 'Dismiss'
  }),
  ClickNotificationSupportLink: ({
    screenName,
    screenMode
  }: ClickNotificationSupportLinkEvent) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName,
    screenMode,
    action: 'ControlHyperLinkClicked',
    controlName: 'ContactSupport'
  }),
  // ------------- ChangePlanModal --------------
  ModalWindowDisplayed: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModal',
    action: 'ModalWindowDisplayed'
  }),
  ClickChangePlan: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModal',
    action: 'ControlButtonClicked',
    controlName: 'ChangePlan',
    controlDetail
  }),
  ClickCancelButton: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModal',
    action: 'ControlButtonClicked',
    controlName: 'Cancel',
    controlDetail
  }),
  ClickCloseButton: ({ controlDetail }: PagesPerMonth) => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModal',
    action: 'ControlButtonClicked',
    controlName: 'CloseButton',
    controlDetail
  }),
  // ------------- ChangePlanModalError --------------
  ChangePlanModalErrorDisplayed: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModalError',
    action: 'ModuleDisplayed'
  }),
  ClickChangePlanErrorLink: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModalError',
    action: 'ControlHyperLinkClicked',
    controlName: 'ContactSupport'
  }),
  ClickChangePlanErrorDismiss: () => ({
    screenPath: '/ReactPaasDashboardUpdatePlan/UpdatePlan/',
    screenName: 'ChangePlanModalError',
    action: 'ControlButtonClicked',
    controlName: 'Dismiss'
  })
}
