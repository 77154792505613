import styled from 'styled-components'
import Card from '@veneer/core/dist/esm/scripts/card'
import tokens from '@veneer/tokens'
import Button from '@veneer/core/dist/esm/scripts/button'
import { CUSTOM_COLORS } from '../../__mocks__/constants/styles.constants'

const { white, highContrastGray, gray1, hpBlue7 } = tokens.color
const {
  size1,
  size2: layoutSize2,
  size4,
  size5,
  size7,
  size8,
  mdMin,
  mdMax,
  lgMin,
  lgMax,
  xlMin,
  cornerRadius2
} = tokens.layout
const { size0, size2, size3, lineHeight1, family0 } = tokens.typography

const getBorderColor = (
  isCurrent: boolean,
  isRecommended: boolean,
  disabled: boolean
): string => {
  if (isCurrent) return `border: 1.5px solid ${CUSTOM_COLORS.colorGray}`
  if (disabled && !isCurrent) return `border: 1.5px solid ${hpBlue7}`
  if (isRecommended) return `border: 1.5px solid #be00a1`
  return null
}

export const Container = styled(Card)<{
  $current?: boolean
  $recommended?: boolean
}>`
  && {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: ${white};
    padding: ${size5} ${size7} ${size8};

    @media only screen and (min-width: ${mdMin}) and (max-width: ${mdMax}),
      only screen and (min-width: ${lgMin}) and (max-width: ${lgMax}),
      only screen and (min-width: ${xlMin}) and (max-width: 1310px) {
      padding: ${size5} ${size4} ${size8};
    }

    ${({ $current, $recommended, $disabled }) =>
      getBorderColor($current, $recommended, $disabled)}
`

export const CardPill = styled.span<{
  $current?: boolean
  $recommended?: boolean
  $disabled?: boolean
}>`
  position: absolute;
  border-radius: 0 0 ${cornerRadius2} ${cornerRadius2};
  width: 98px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${size1};
  font-size: ${size0};
  background-color: ${CUSTOM_COLORS.colorGray};
  color: ${white};
  top: -1px;

  ${({ $recommended, $current, $disabled }) => {
    if ($recommended && !$current)
      return `
        background-color: #be00a1;
        border: 1.5px solid #be00a1;
      `
    else if ($disabled && !$current)
      return `
        background-color: ${hpBlue7};
        border: 1.5px solid ${hpBlue7};
        `
  }};
`

export const IconContainer = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${size5};
`

export const Title = styled.h2`
  font-family: ${family0};
  font-size: ${size3};
  line-height: 1;
  margin: ${size1} 0 ${size8};

  span {
    font-size: ${size2};
  }
`

export const SubscribeBtn = styled(Button)`
  && {
    padding: ${size2} ${size4};

    color: ${(props) => (props.disabled ? highContrastGray : hpBlue7)};
    > span {
      margin: 2px 0 0;
    }
  }
`

export const Divider = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${gray1};
  margin: ${size8} 0 ${layoutSize2};
`

export const Perk = styled.p`
  align-self: flex-start;
  display: flex;
  align-items: center;
  font-size: ${size0};
  line-height: ${lineHeight1};
  margin-top: ${size1};
  color: #4e4e4e;

  > svg {
    margin-right: ${size1};
    color: ${CUSTOM_COLORS.colorGreen};
  }
`
