export const getPendingSku = (data) => {
  try {
    const editPlanOptions = data?.optionsData?.entities?.filter(
      (item) => item.entityId === data?.instantInk?.entityId
    )
    const pendingPlanOptions = editPlanOptions[0]?.edit?.filter(
      (item) => item.enabled === false
    )
    const pendinPlanChangeSku =
      pendingPlanOptions[0]?.product?.value?.productSku
    return (
      data?.pendingData?.contents?.find(
        (entity: any) =>
          entity?.type === 'planChange' &&
          entity?.reason?.value?.pendingSku === pendinPlanChangeSku
      )?.reason?.value?.pendingSku || ''
    )
  } catch (error) {
    console.error('Error fetching pending SKU:', error)
    return ''
  }
}
