import React, { FC } from 'react'
import { Container, InfoText, RemovePaperLink, RemovePaperText } from './styles'

interface PageDescriptionProps {
  hasPaperAccess?: boolean
  t?: any
  subscriptionId?: string
}

const PageDescription: FC<PageDescriptionProps> = ({
  hasPaperAccess = false,
  t,
  subscriptionId
}) => {
  return (
    <Container>
      {hasPaperAccess ? (
        <>
          <InfoText>
            {t(
              'update-plan.header.paper-text',
              'Update your plan by selecting a new one. Monthly plan includes paper and ink.'
            )}
          </InfoText>
          <RemovePaperText data-testid="remove-paper">
            {`${t('update-plan.header.need-to', 'Need to')} `}
            <RemovePaperLink
              href={`/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper&redirectTo=updatePlan`}
            >
              {t(
                'update-plan.header.remove-paper',
                'remove Paper Add-on Service'
              )}
            </RemovePaperLink>
            {t('update-plan.header.your-plan', ' from your plan?')}
          </RemovePaperText>
        </>
      ) : (
        <InfoText>
          {t(
            'update-plan.header.description',
            'View your current plan or select a different option below to change plans.'
          )}
        </InfoText>
      )}
    </Container>
  )
}

export default PageDescription
