import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { size2, size5, size6, size8, xsMax, mdMin, lgMin } = tokens.layout

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${size6} ${size5};
  margin: ${size2} 0 ${size8};
  float: left;
  width: 100%;

  @media (min-width: ${xsMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${mdMin}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${lgMin}) {
    grid-template-columns: repeat(4, 1fr);
  }
`
