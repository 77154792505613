import styled from 'styled-components'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import { tokens } from '@veneer/tokens'

const { lgMin, smMin } = tokens.layout
const { gray9 } = tokens.color
const { size7, lineHeight7 } = tokens.typography

export const StyledModal = styled(Modal)`
  && {
    .vn-modal--content {
      padding: 40px 48px;

      @media (min-width: ${lgMin}) {
        width: 640px;
      }
    }

    .vn-modal__close {
      color: ${gray9};
    }

    .vn-modal--title {
      font-size: ${size7};
      line-height: ${lineHeight7};
    }

    .vn-modal--footer {
      display: flex;
      padding-top: 40px;
      justify-content: right;
      @media (max-width: ${smMin}) {
        justify-content: center;
      }
    }
  }
`
export const FooterCard = styled.div`
  display: flow;
`
