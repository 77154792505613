import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { gray12, hpBlue7 } = tokens.color
const { size2, lineHeight3 } = tokens.typography
const { smMin, size8: layoutSize8 } = tokens.layout

export const Container = styled.div`
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray12};
  margin-bottom: 40px;

  @media (max-width: ${smMin}) {
    margin-bottom: ${layoutSize8};
  }
`

export const InfoText = styled.p``

export const RemovePaperText = styled.p`
  margin-top: 2px;
`

export const RemovePaperLink = styled.a`
  color: ${hpBlue7};
  cursor: pointer;
`
