import React, { useEffect, useMemo, useState } from 'react'
import {
  HpxHeader,
  useNotification,
  PrinterListOption
} from '@monetization/hpaip-ui-shared-components'
import {
  SubscriptionStateEnum,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import dayjs from 'dayjs'
import {
  getInkOffers,
  getUserOffers,
  useLazyGetProducts
} from '../../api/services'
import { useBillingCycle } from '../../hooks/useBillingCycle'
import { useSubscriptionOptions } from '../../hooks/useSubscriptionOptions'
import { InkOffer, InkOffersRecord } from '../../types/instantink'
import { StyledHeader, StyledPage, DropDownStyle } from './Page.styles'
import { PendingNotification } from '../PendingNotification/PendingNotification'
import { CommonProps } from 'src/types/mfeProps'
import NotificationContainer from './NotificationContainer'
import { useSubscription } from 'src/hooks/useSubscription'
import { MultipleSubscriptionPlan } from 'src/types/planInfo'
import { MainContent } from './MainContent'
import { getPendingSku } from 'src/utils/getPendingSku'

interface PageProps {
  commonProps?: CommonProps
  printerProps?: any
  setIndex?: React.Dispatch<React.SetStateAction<number>>
  index?: number
  productData?: any
}

export interface NotificationData {
  title?: string
  description?: string
  notificationID?: string
  showCloseButton?: boolean
  notificationType?: any
}

export const Page: React.FC<PageProps> = ({
  commonProps,
  printerProps,
  setIndex,
  index,
  productData
}) => {
  const { authProvider, stack, t } = commonProps
  const { setNotification } = useNotification()
  const [errorNotification, setErrorNotification] = useState<boolean>(false)
  const [revertPlan, setRevertPlan] = useState<boolean>(false)
  const [isAddedPaperNotification, setIsAddedPaperNotification] =
    useState(false)
  const subscriptionService = useSubscription({ authProvider, stack })
  const [getProducts, products] = useLazyGetProducts()
  const [getOptions, options] = useSubscriptionOptions({ authProvider, stack })
  const [getBillingCycles, billingCycles] = useBillingCycle({
    authProvider,
    stack
  })
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isDowngrade, setIsDowngrade] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<InkOffer>(null)
  const [chosenPendingOffer, setChosenPendingOffer] = useState<InkOffer>(null)
  const [errors, setErrors] = useState<any[]>([])
  const [printerData, setPrinterData] = useState<any>()
  const customerRequestedPlanChange = getPendingSku(printerProps?.printerData)
  const [pendingNotificationType, setPendingNotificationType] =
    useState<string>(null)
  const [showRevertloader, setShowRevertloader] = useState<boolean>(false)
  const [multipleSubscriptions, setMultipleSubscriptions] = useState<string[]>(
    []
  )
  const flags = useFlags()

  const loading =
    products?.loading ||
    options?.loading ||
    printerData?.isLoading ||
    billingCycles.loading

  const billingCycleEndDate =
    billingCycles?.data?.[0]?.items?.slice(-1)[0]?.endDate

  const nextBillingCycleStartDate =
    dayjs(billingCycleEndDate).format('MMM D, YYYY')

  useEffect(() => {
    function fetchData() {
      try {
        const currentPrinterData = printerProps?.printerData ?? {}
        setPrinterData(currentPrinterData)
      } catch (error) {
        setPrinterData({
          error: true,
          isLoading: false,
          printer: null,
          called: true
        })
        console.error('Error retrieving subscriptions:', error)
      }
    }
    fetchData()
  }, [printerProps?.printerData])

  useEffect(() => {
    if (productData && productData.length > 1) {
      setMultipleSubscriptions(productData)
    }
  }, [productData])

  /**
   * Wait for the list of products from Mangento and list of available options from BizLogic
   * to be loaded, once both are available, it will filter the instant ink plans from the list of productSku
   * and then, it will use the list of options from BizLogic to filter those plan to only show the ones the customer
   * is supposed to see and which is the recommended one if any.
   **/
  const offers: InkOffersRecord = useMemo((): InkOffersRecord => {
    if (!products?.data || !options?.data) return null

    const inkOffers: InkOffersRecord = getInkOffers(
      options?.data,
      products?.data
    )

    let userOffers
    if (
      options?.data?.entities &&
      options?.data?.entities?.length > 0 &&
      options?.data?.entities[0]?.edit != null
    ) {
      userOffers = getUserOffers(inkOffers, options?.data?.entities[0]?.edit)
    } else {
      userOffers = inkOffers
    }
    return userOffers
  }, [products?.data, options?.data])

  const printerBundle = products?.data?.products?.items[0]
  const inkBundle = {
    options: printerBundle?.items?.reduce((accumulator, item) => {
      const filtered = item?.options?.filter(
        (option) => option?.product?.hp_product_type_label === 'leaseReceivable'
      )
      return accumulator.concat(filtered)
    }, [])
  }

  const priceLowValue =
    printerBundle !== undefined
      ? inkBundle?.options?.[0]?.product?.price?.regularPrice?.amount?.value
      : 0

  /**
   * After the customer changes the plan (downgrade or upgrade), the change will only take effect
   * at the end of the current billing cycle, so to inform the customer which plan is updating to
   * we need to check the `pendingSku` property in the instant ink entity, this will be the instant ink plan SKU
   * the customer chose to change to.
   **/
  const pendingOffer: InkOffer = useMemo((): InkOffer => {
    if (!printerData?.instantInk || !offers) return null
    const pendingSku = customerRequestedPlanChange
    return chosenPendingOffer ?? offers[pendingSku]
  }, [offers, printerData, chosenPendingOffer])

  const currentOffer: InkOffer = useMemo((): InkOffer => {
    if (!printerData?.instantInk || !offers) return null
    const currentOfferSku = printerData?.instantInk?.product?.value?.productSku
    return offers[currentOfferSku] ?? null
  }, [offers, printerData?.printer])

  const resetState = () => {
    setShowDialog(false)
    setSelectedOffer(null)
    setIsDowngrade(false)
    setRevertPlan(false)
    setIsAddedPaperNotification(false)
  }

  const handleOnSubscribe = (offer: InkOffer, isDowngrade: boolean): void => {
    setIsDowngrade(isDowngrade)
    setSelectedOffer(offer)
    setShowDialog(true)
  }

  const handleChangePlanDialogClose = () => {
    resetState()
  }

  const getChangeInkPlanPayload = () => {
    return {
      optionId: selectedOffer?.optionId
    }
  }
  const handleChangePlanDialogConfirm = () => {
    setErrorNotification(false)
    subscriptionService
      .patch(printerData?.root?.subscriptionId, getChangeInkPlanPayload())
      .then(() => {
        setChosenPendingOffer(selectedOffer)
        resetState()
        getOptions(
          printerData?.root?.subscriptionId,
          printerData?.instantInk?.entityId
        )
      })
      .catch(() => {
        setErrorNotification(true)
        resetState()
      })
  }

  const getCurrentOfferPayload = () => {
    return {
      optionId: currentOffer?.optionId //+ '123'
    }
  }
  const handleRevertPlan = () => {
    setShowRevertloader(true)
    subscriptionService
      .patch(printerData?.root?.subscriptionId, getCurrentOfferPayload())
      .then(() => {
        setPendingNotificationType(null)
        setShowRevertloader(false)
        setChosenPendingOffer(currentOffer)
        resetState()
        setRevertPlan(true)
        getOptions(
          printerData?.root?.subscriptionId,
          printerData?.instantInk?.entityId
        )
      })
      .catch(() => {
        setShowRevertloader(false)
        setNotification(null)
        setPendingNotificationType('planRevertError')
        resetState()
      })
  }

  useEffect(() => {
    async function fetchData() {
      const dataSources: any[] = [options, printerData, products]
      setErrors(dataSources.filter((v) => !!v['error']))
    }
    if (printerData !== undefined) fetchData()
  }, [
    options?.error,
    printerData?.error,
    products?.error,
    billingCycles?.error
  ])

  useEffect(() => {
    const fetchData = () => {
      const subscriptionId = printerData?.root?.subscriptionId
      const entityId = printerData?.instantInk?.entityId

      if (!entityId) {
        console.error('Missing instant ink entity id, result:', entityId)
        return
      }

      const printerSku =
        printerData?.instantInk?.product?.value?.parentProductSku

      if (!printerSku) {
        console.error('Missing printer sku, result:', printerSku)
        return
      }

      getProducts({
        variables: { sku: printerSku }
      })
      getOptions(subscriptionId, entityId)
      // billing cycle data is not available when subscription state is "pending"
      if (
        printerData?.printer?.state.toLowerCase() !==
        SubscriptionStateEnum.PENDING
      ) {
        getBillingCycles(subscriptionId, printerData?.instantInk?.entityId)
      }
    }

    if (
      printerData?.printer !== undefined &&
      printerData?.instantInk !== undefined &&
      !printerData?.isLoading &&
      !products.called &&
      !options.called
    ) {
      fetchData()
    }
  }, [printerData])

  const printerState = useMemo(
    () => printerData?.printer?.state?.toLowerCase(),
    [printerData?.printer?.state]
  )

  const isPrinterCancelledORReturned = useMemo(() => {
    return printerState === SubscriptionStateEnum.CANCELED
  }, [printerState])

  useEffect(() => {
    if (
      pendingOffer &&
      pendingOffer?.sku !== currentOffer?.sku &&
      printerState !== SubscriptionStateEnum.CANCELED &&
      printerState !== SubscriptionStateEnum.CANCELING &&
      printerState !== SubscriptionStateEnum.SUSPENDED
    ) {
      setNotification(null)
      setPendingNotificationType('planPending')
    }
  }, [pendingOffer, currentOffer, chosenPendingOffer])

  useEffect(() => {
    if (isAddedPaperNotification) {
      setIsAddedPaperNotification(true)
    }
  }, [isAddedPaperNotification])

  const addPaperEnabled: boolean =
    flags?.enableAddPaperPostEnrollment &&
    !printerData?.activePaperSubscription &&
    printerProps?.printerData?.root?.state == SubscriptionStateEnum.SUBSCRIBED

  const isError = Boolean(products.error || !products.called)

  //code related to multiple subscriptions
  const handlePrinterSelect = (index: MultipleSubscriptionPlan) => {
    setIndex(index?.id)
    localStorage.setItem('index', index?.id.toString())
    alert('Data saved to localStorage!')
  }
  const booleanFlags = {
    addPaperEnabled,
    loading,
    isError,
    isPrinterCancelledORReturned,
    showDialog,
    isDowngrade
  }
  const actions = {
    handleOnSubscribe,
    setIsAddedPaperNotification,
    handleChangePlanDialogClose,
    handleChangePlanDialogConfirm
  }
  const inkOffers = {
    currentOffer,
    pendingOffer
  }

  return (
    <StyledPage>
      <PendingNotification
        t={t}
        pendingNotificationType={pendingNotificationType}
        pendingOffer={pendingOffer}
        nextBillingCycleStartDate={nextBillingCycleStartDate}
        showRevertloader={showRevertloader}
        handleRevertPlan={handleRevertPlan}
      />

      <NotificationContainer
        printerProps={printerProps}
        errorNotification={errorNotification}
        revertPlan={revertPlan}
        isAddedPaperNotification={isAddedPaperNotification}
      />

      {multipleSubscriptions.length > 0 && (
        <DropDownStyle>
          <PrinterListOption
            t={t}
            PrinterDetails={productData}
            defaultPrinter={index}
            onPrinterSelect={handlePrinterSelect}
            isOpen={true}
          ></PrinterListOption>
        </DropDownStyle>
      )}

      <StyledHeader>
        {!isPrinterCancelledORReturned && (
          <HpxHeader
            title={t('update-plan.header.title', 'Update HP All-In Plan')}
            showLogo={false}
          />
        )}
      </StyledHeader>
      <MainContent
        flags={flags}
        booleanFlags={booleanFlags}
        actions={actions}
        inkOffers={inkOffers}
        t={t}
        subscriptionId={printerData?.root?.subscriptionId}
        printerData={printerData}
        errors={errors}
        offers={offers}
        priceLowValue={priceLowValue}
        billingCycles={billingCycles}
        billingCycleEndDate={dayjs(billingCycleEndDate).format('MMM DD, YYYY')}
        nextBillingCycleStartDate={nextBillingCycleStartDate}
        selectedOffer={selectedOffer}
        commonProps={commonProps}
      />
    </StyledPage>
  )
}
