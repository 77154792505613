import React, { useCallback } from 'react'
import { InkPlanCard } from '../InkPlanCard'
import { InkOffersRecord, InkOffer } from 'src/types/instantink'
import { Grid } from './InkPlanList.styles'
import {
  PrinterSubscriptionData,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
type Props = {
  printerData: PrinterSubscriptionData
  offers: InkOffersRecord
  currentOffer: InkOffer
  pendingOffer: InkOffer
  asLowAsPrice?: number
  t?: any
  // eslint-disable-next-line no-unused-vars
  onSubscribe: (offer: InkOffer, isDowngrade: boolean) => void
}

export const InkPlanList = ({
  offers,
  currentOffer,
  pendingOffer,
  printerData,
  onSubscribe,
  asLowAsPrice,
  t
}: Props) => {
  const { printer } = printerData

  /**
   * Check if the offer button should be enabled or disabled based on the following criteria:
   *
   * 1. is subscription in pending state, all offers should be disabled;
   * 2. is current and has no pending offer, then disabled;
   * 3. is current and has pending offer, then enable;
   * 4. is pending offer, then disabled;
   * 5. is neither current or pending offer, enable;
   *
   * Pending offer is the offer chosen by the user to change to, which only changes to current at the
   * end of the current billing cycle.
   **/
  const shouldBeDisabled = useCallback(
    (offer: InkOffer): boolean => {
      const isCurrent = isCurrentOffer(offer)
      const isPendingOffer = offer.sku === (pendingOffer?.sku ?? false)
      // when subscription is pending the use shouldn't be able to change plan
      const printerState = printer.state.toLowerCase()
      if (
        printerState === SubscriptionStateEnum.PENDING ||
        printerState === SubscriptionStateEnum.CANCELING ||
        printerState === 'suspended'
      )
        return true
      if (isCurrent && currentOffer.sku === pendingOffer?.sku) return true
      // current offer should not be disabled when a pending offer exists
      //if (isCurrent && pendingOffer != null) return false
      return isCurrent || isPendingOffer
    },
    [pendingOffer, printer.state]
  )

  const handleOnSubscribe = useCallback(
    (offer: InkOffer) => {
      // the disabled attribute can be easily removed from the button element,
      // this validation ensures that a plan that should be disabled will not trigger
      // the `onSubscribe` callback and potentially break the application;
      if (shouldBeDisabled(offer)) return

      const isDowngrade = offer.pages < currentOffer.pages
      if (onSubscribe) onSubscribe(offer, isDowngrade)
    },
    [currentOffer, onSubscribe, shouldBeDisabled]
  )

  const isCurrentOffer = (offer: InkOffer): boolean => {
    return offer.sku === currentOffer?.sku
  }

  const getCalculatedPrice = useCallback(
    (offer: InkOffer) => {
      const cureentOfferPrice = currentOffer?.price + asLowAsPrice
      const offerPrice = offer?.price + asLowAsPrice
      const diff = isNaN(cureentOfferPrice - offerPrice)
        ? 0
        : cureentOfferPrice - offerPrice
      if (diff === 0) {
        const amount = Math.abs(cureentOfferPrice).toFixed(2)
        return `$${amount} / ${t('inkPlanList.month', 'month')}`
      } else {
        const prefix =
          diff > 0
            ? t('inkPlanList.saveText', 'Save')
            : t('inkPlanList.AddText', 'Add')
        const amount = Math.abs(diff).toFixed(2)
        return `${prefix} $${amount} / ${t('inkPlanList.month', 'month')}`
      }
    },
    [asLowAsPrice, currentOffer?.price, t]
  )
  const getTooltip = useCallback((): string | null => {
    if (printer.state.toLowerCase() === SubscriptionStateEnum.PENDING) {
      return t('update-plan.inkPlanCard.tooltips.pending', {
        defaultValue:
          'You will be able to change plans after you complete your printer setup.'
      })
    } else if (
      printer.state.toLowerCase() === SubscriptionStateEnum.CANCELING
    ) {
      return t('update-plan.inkPlanCard.tooltips.cancelling', {
        defaultValue:
          'The subscription of the selected printer has a cancellation in progress.'
      })
    } else if (printer.state.toLowerCase() === 'suspended') {
      return t('update-plan.inkPlanCard.tooltips.suspended', {
        defaultValue:
          'Your account is currently suspended. You can’t​ make changes until you resolve this issue.'
      })
    }

    return null
  }, [printer.state, t])

  if (!offers) return null

  const showInkPlanCard = async (offer: InkOffer) => {
    const isCurrent = await isCurrentOffer(offer)

    if (printer.state.toLowerCase() === SubscriptionStateEnum.CANCELING) {
      return isCurrent
    }

    return offer?.enable || isCurrent
  }
  const sortedOffers = Object.values(offers).sort((a, b) => a.pages - b.pages)

  return (
    <Grid data-testid="InkPlanList">
      {sortedOffers.map((offer) => {
        const calculatedPrice = getCalculatedPrice(offer)

        return (
          showInkPlanCard(offer) &&
          calculatedPrice && (
            <InkPlanCard
              key={offer.sku}
              {...offer}
              disabled={shouldBeDisabled(offer)}
              current={isCurrentOffer(offer)}
              tooltip={getTooltip()}
              calculatedPrice={calculatedPrice}
              onSubscribe={() => handleOnSubscribe(offer)}
              printerData={printerData}
              printerState={printer?.state}
              t={t}
            />
          )
        )
      })}
    </Grid>
  )
}
