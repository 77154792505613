import styled from 'styled-components'
import Button from '@veneer/core/dist/esm/scripts/button'
import { tokens } from '@veneer/tokens'

const { gray12 } = tokens.color
const { family0, size5, size2, lineHeight5, lineHeight3 } = tokens.typography

const { size1: layoutSize1, size6: layoutSize6, mdMin } = tokens.layout

export const ErrorSectionContainer = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;

  @media (min-width: ${mdMin}) {
    margin-top: 30px;
  }
`

export const ErrorSectionTitle = styled.h1`
  font-family: ${family0};
  font-size: ${size5};
  font-weight: 400;
  color: ${gray12};
  line-height: ${lineHeight5};
`

export const ErrorSectionDescription = styled.p`
  font-family: ${family0};
  font-size: ${size2};
  font-weight: 400;
  color: ${gray12};
  line-height: ${lineHeight3};
  margin-top: ${layoutSize1};
`

export const ErrorSectionButton = styled(Button)`
  && {
    margin-top: ${layoutSize6};
  }
`
