import styled from 'styled-components'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import { tokens } from '@veneer/tokens'

const { size2 } = tokens.typography

export const ErrorModal = styled(Modal)`
  && {
    .vn-modal--content {
      vertical-align: top;
    }

    .vn-modal--footer {
      gap: ${size2};
      justify-content: end;
    }
`
