import React from 'react'
import { Container } from './Page.styles'
import { InkPlanList } from '../InkPlanList'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import PageDescription from '../PageDescription'
import { MemoizedPaperWrapper } from '../PaperWrapper/PaperWrapper'
import { DisclaimerText } from '../DisclaimerText'
import { ChangePlanDialog } from '../ChangePlanDialog'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import { InkOffer, InkOffersRecord } from '../../types/instantink'
import { CommonProps } from 'src/types/mfeProps'

interface FlagProps {
  addPaperEnabled?: boolean
  isPrinterSubscribedOrUpdating?: boolean
  loading?: boolean
  isError?: boolean
  isPrinterCancelledORReturned?: boolean
  showDialog?: boolean
  isDowngrade?: boolean
}

interface ActionsProps {
  handleOnSubscribe: (offer: InkOffer, isDowngrade: boolean) => void
  setIsAddedPaperNotification: (value: boolean) => void
  handleChangePlanDialogClose: () => void
  handleChangePlanDialogConfirm: () => void
}

interface InkOfferProps {
  currentOffer?: InkOffer
  pendingOffer?: InkOffer
}

export interface MainContentProps {
  booleanFlags?: FlagProps
  actions?: ActionsProps
  inkOffers?: InkOfferProps
  flags?: any
  t?: any
  subscriptionId?: string
  printerData?: any
  errors?: any[]
  offers?: InkOffersRecord
  priceLowValue?: number
  billingCycles?: any
  isDowngrade?: boolean
  billingCycleEndDate?: string
  nextBillingCycleStartDate?: string
  selectedOffer?: any
  commonProps?: CommonProps
}

export const MainContent: React.FC<MainContentProps> = ({
  booleanFlags,
  actions,
  inkOffers,
  flags,
  t,
  subscriptionId,
  printerData,
  errors,
  offers,
  priceLowValue,
  billingCycles,
  billingCycleEndDate,
  nextBillingCycleStartDate,
  selectedOffer,
  commonProps
}) => (
  <Container>
    {!errors.length && !booleanFlags.isPrinterCancelledORReturned && (
      <PageDescription
        hasPaperAccess={
          printerData?.isRemovePaper && flags?.enableManagePaperPostEnrollment
        }
        t={t}
        subscriptionId={subscriptionId}
      />
    )}
    <LoadingHandler
      loading={booleanFlags.loading}
      error={booleanFlags.isError}
      $position="absolute"
      customError={
        <ErrorSection
          t={t}
          title="Something went wrong"
          description="Your account information did not load."
          buttonText="Try again"
        />
      }
    />
    {!booleanFlags.loading &&
      !errors.length &&
      !booleanFlags.isPrinterCancelledORReturned &&
      printerData?.printer !== undefined && (
        <>
          <InkPlanList
            printerData={printerData}
            offers={offers}
            currentOffer={inkOffers.currentOffer}
            pendingOffer={inkOffers.pendingOffer}
            onSubscribe={actions.handleOnSubscribe}
            asLowAsPrice={priceLowValue}
            t={t}
          />
          {booleanFlags.addPaperEnabled && (
            <MemoizedPaperWrapper
              printerData={printerData}
              t={t}
              handleAddedPaperNotification={actions.setIsAddedPaperNotification}
              commonProps={commonProps}
              currentOffer={inkOffers.currentOffer}
              priceLowValue={priceLowValue}
            />
          )}
          <DisclaimerText t={t} />
          <ChangePlanDialog
            show={booleanFlags.showDialog}
            showError={!!billingCycles.error}
            onClose={actions.handleChangePlanDialogClose}
            onConfirm={actions.handleChangePlanDialogConfirm}
            isDowngrade={booleanFlags.isDowngrade}
            currentBillingCycleEndDate={billingCycleEndDate}
            nextBillingCycleStartDate={nextBillingCycleStartDate}
            currentOffer={inkOffers.currentOffer}
            chosenOffer={selectedOffer}
            asLowAsPrice={priceLowValue}
            t={t}
          />
        </>
      )}
  </Container>
)
