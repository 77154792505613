import { SubscriptionOptionEdit } from '@monetization/hpaip-notification-rules-react'
import { InkOffersRecord } from '../../types/instantink'

/*
 * Filter magento ink products with subscription options data from BizLogic
 * to return a list of offers for the current user and which offer is recommended
 *
 * @param {InkOffersRecord} offers – Instant Ink products from Magento API
 * @param {SubscriptionOptionEdit[]} options – List of subscription options from BizLogic
 * @return {InkOffersRecord } – filtered and updated list of offers the user can subscribe to
 */
export const getUserOffers = (
  offers: InkOffersRecord,
  options: SubscriptionOptionEdit[]
): InkOffersRecord => {
  if (!offers || !options) return

  return options.reduce((acc, option) => {
    // validate if magento ink offer exists for the given option product sku
    const { recommended, optionId, enabled } = option
    const offer = offers[option.product.value.productSku]
    if (offer) {
      acc[offer.sku] = {
        ...offer,
        recommended: recommended,
        optionId: optionId,
        enable: enabled // necessary addition to implement change plan feature
      }
    }

    return acc
  }, {})
}
