import React, { FC, useState, useMemo } from 'react'
import {
  PaperDetails,
  AddOnPaperStepOne,
  Row,
  PaperOnPaasModal
} from '@monetization/hpaip-ui-shared-components'
import Group from '@veneer/core/dist/scripts/group'
import Button from '@veneer/core/dist/scripts/button/button'
import useUpdateSubscription from 'src/hooks/useUpdateSubscription'
import { ErrorModal } from './styles'
import { CommonProps } from 'src/types/mfeProps'
import { InkOffer } from '../../types/instantink'
import useShippingAddress from 'src/hooks/useShippingAddress'
import { redirectUrl } from 'src/utils'

interface PaperWrapperProps {
  printerData?: any
  t?: any
  handleAddedPaperNotification?: (value: boolean) => void
  commonProps?: CommonProps
  currentOffer?: InkOffer
  priceLowValue?: number
}

export const PaperWrapper: FC<PaperWrapperProps> = ({
  printerData,
  t,
  handleAddedPaperNotification,
  commonProps,
  currentOffer,
  priceLowValue
}) => {
  const [modalState, setModalState] = useState({
    stepOne: false,
    stepTwo: false,
    error: false
  })

  const handlePaperStepOneSubmit = async () => {
    const success = await updateSubscription()
    setModalState({ stepOne: false, stepTwo: success, error: !success })
  }

  const { enabled, optionId, priceInfo } = printerData?.paperInfo ?? null

  const subscriptionId: string = printerData?.root?.subscriptionId

  const { updateSubscription } = useUpdateSubscription(
    subscriptionId,
    optionId,
    commonProps
  )

  const optimizedValues = useMemo(() => {
    const amount = isNaN(currentOffer?.price + priceLowValue)
      ? 0
      : currentOffer?.price + priceLowValue

    const currentPlanPrice = Math.abs(amount).toFixed(2)
    const totalPlanPrice =
      (parseFloat(currentPlanPrice) + priceInfo).toFixed(2) || 0

    const currentPagesPerMonth = currentOffer?.pages ?? 0

    return { totalPlanPrice, currentPagesPerMonth }
  }, [currentOffer?.price, priceLowValue, currentOffer?.pages])

  const { totalPlanPrice, currentPagesPerMonth } = optimizedValues

  // shipping address logic
  const { shippingAddress } = useShippingAddress(
    printerData?.printer?.shippingAddressId,
    commonProps
  )

  const handleChange = () =>
    redirectUrl('/account-details/shipping-billing', commonProps?.navigation)

  return (
    <>
      {enabled && (
        <>
          <Row>
            <PaperDetails
              pricePlan={String(priceInfo)}
              pagesPerMonth={String(currentPagesPerMonth)}
              handleButtonClick={() =>
                setModalState({ ...modalState, stepOne: true })
              }
            />
          </Row>

          <AddOnPaperStepOne
            show={modalState.stepOne}
            pricePlan={String(priceInfo)}
            pagesPerMonth={String(currentPagesPerMonth)}
            onClose={() => setModalState({ ...modalState, stepOne: false })}
            onSubmit={handlePaperStepOneSubmit}
          />

          <PaperOnPaasModal
            onClose={() => {
              handleAddedPaperNotification(true)
              setModalState({ ...modalState, stepTwo: false })
            }}
            show={modalState.stepTwo}
            pricePlan={String(totalPlanPrice)}
            pagesPerMonth={String(currentPagesPerMonth)}
            shippingAddress={{
              fullName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
              address: `${shippingAddress?.address}`,
              address2: `${shippingAddress?.address2}`,
              city: `${shippingAddress?.city}`,
              state: `${shippingAddress?.state}`,
              postalCode: `${shippingAddress?.postalCode}`,
              countryCode: `${shippingAddress?.countryCode}`
            }}
            handleChange={handleChange}
          />

          <ErrorModal
            align="start"
            closeButton={true}
            show={modalState.error}
            onClose={() => setModalState({ ...modalState, error: false })}
            data-testid="modal-error"
            footer={
              <Group>
                <Button
                  appearance="secondary"
                  onClick={handlePaperStepOneSubmit}
                  role="try-again"
                >
                  {t(
                    'update-plan.paper-wrapper.error-section.button',
                    'Try it again'
                  )}
                </Button>
              </Group>
            }
            title={t(
              'update-plan.paper-wrapper.error-section.title',
              'Something went wrong'
            )}
          >
            <p>
              {t(
                'update-plan.paper-wrapper.error-section.description',
                'It has not been possible to add the Paper Add-on service to your plan.'
              )}
            </p>
          </ErrorModal>
        </>
      )}
    </>
  )
}

export const MemoizedPaperWrapper = React.memo(
  PaperWrapper,
  (prevProps, nextProps) =>
    prevProps.printerData?.optionsData === nextProps.printerData?.optionsData &&
    prevProps.printerData === nextProps.printerData
)
