import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { Link } from '@monetization/hpaip-ui-shared-components'

const { size7, size8 } = tokens.layout
const { size2, lineHeight3 } = tokens.typography
const { hpBlue7 } = tokens.color
export const Container = styled.section`
  margin: ${size8} 0;
`
export const StyledAnchor = styled(Link)`
  font-size: ${size2} !important;
  color: ${hpBlue7} !important;
`
export const Text = styled.p`
  font-size: ${size2};
  line-height: ${lineHeight3};

  &:not(:last-child) {
    margin: ${size7} 0;
  }
`
