import React, { useState, useEffect } from 'react'
import { SubscriptionsStateClient } from '@monetization/hpaip-notification-rules-react'
import InitialComponent from '../InitialComponent/InitialComponent'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import { CommonProps } from 'src/types/mfeProps'
import useFetchMultipleSubscription from 'src/hooks/useFetchMultipleSubscription'

interface Props {
  commonProps: CommonProps
}

const SubscriptionInfo: React.FunctionComponent<Props> = ({ commonProps }) => {
  const [subscriptionId, setSubscriptionId] = useState<string>(null)
  const { authProvider, stack, t } = commonProps
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [subscriptionsData, setSubscriptionsData] = useState<any[]>([])

  const getStoredIndex = () => {
    const storedIndex = localStorage.getItem('index')
    const parsedIndex = storedIndex ? parseInt(storedIndex, 10) : NaN
    return isNaN(parsedIndex) ? 0 : parsedIndex
  }
  const [index, setIndex] = useState<number>(getStoredIndex)

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true)
      try {
        const subscriptionsClient = new SubscriptionsStateClient(
          authProvider,
          stack
        )
        const data = await subscriptionsClient.getAllSubscriptions()
        setSubscriptionsData(data)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
        setError(true)
        //setError('Failed to fetch subscriptions')
      } finally {
        //setLoading(false)
      }
    }

    fetchSubscriptions()
  }, [authProvider, stack])

  useEffect(() => {
    if (subscriptionsData.length > 0) {
      setSubscriptionId(subscriptionsData[index]?.subscriptionId)
    }
  }, [index, subscriptionsData])

  const { productData } = useFetchMultipleSubscription(subscriptionsData)

  return (
    <>
      {subscriptionId ? (
        <InitialComponent
          subscriptionId={subscriptionId}
          commonProps={commonProps}
          setIndex={setIndex}
          index={index}
          productData={productData}
        />
      ) : (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorSection
              t={t}
              title="Something went wrong"
              description="Your account information did not load."
              buttonText="Try again"
            />
          }
        ></LoadingHandler>
      )}
    </>
  )
}

export default SubscriptionInfo
