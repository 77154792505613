import React from 'react'

export default function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
    >
      <path
        d="M16.0536 10.0529C16.4019 10.2886 16.4019 10.8933 16.0536 11.1289L9.43314 15.6078C9.08477 15.8434 8.52046 15.8434 8.1721 15.6078L1.55167 11.1289C1.2033 10.8933 1.2033 10.2886 1.55167 10.0529"
        stroke="#AF00AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0536 13.0578C16.4019 13.2935 16.4019 13.8981 16.0536 14.1338L9.43314 18.6127C9.08477 18.8483 8.52046 18.8483 8.1721 18.6127L1.55167 14.1338C1.2033 13.8982 1.2033 13.2935 1.55167 13.0578"
        stroke="#AF00AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1721 12.6026L1.55167 8.12377C1.20331 7.8881 1.2033 7.50634 1.55167 7.27067L8.1721 2.79184C8.52047 2.55617 9.08478 2.55617 9.43314 2.79184L16.0536 7.27067C16.4019 7.50633 16.4019 7.8881 16.0536 8.12378L9.43314 12.6026C9.08477 12.8383 8.51889 12.8372 8.1721 12.6026Z"
        stroke="#AF00AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
